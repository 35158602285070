import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, of, switchMap } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { MetadataFilter } from '../../domain/models/metadata-filter.model';
import { TilePageSegment } from '../../domain/models/tile-page-segment.model';
import { TilePage } from '../../domain/models/tile-page.model';
import { Tile } from '../../domain/models/tile.model';
import { BaseFacade } from '../base.facade';
import {
  createSegment,
  createTile,
  createTilePage,
  createTilePagePermission,
  deleteSegment,
  deleteTile,
  deleteTilePage,
  deleteTilePagePermissions,
  getTilePages,
  resetSlice,
  updateCurrentTilePage,
  updateMetadataFilters,
  updateSegment,
  updateSegments,
  updateTile,
  updateTilePage,
  updateTilePagePermissions,
  updateTiles,
} from './tilepage.actions';
import { tilePageSelectors } from './tilepage.selectors';
import { Permission } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class TilePageFacade extends BaseFacade {
  tilePages$ = this._store.pipe(select(tilePageSelectors.getTilePages));
  segments$ = this._store.pipe(select(tilePageSelectors.getSegments));
  currentPage$ = this._store.pipe(select(tilePageSelectors.getCurrentTilePage));
  homePage$ = this._store.pipe(select(tilePageSelectors.getHomePage));

  tilePagesForSegmentFunc$ = this._store.pipe(
    select(tilePageSelectors.getTilePagesForSegmentFunc)
  );
  segmentsForTileFunc$ = this._store.pipe(
    select(tilePageSelectors.getSegmentsForTileFunc)
  );
  getTilePageByIdFunc$ = this._store.pipe(
    select(tilePageSelectors.getTilePageByIdFunc)
  );
  getTileByIdFunc$ = this._store.pipe(
    select(tilePageSelectors.getTileByIdFunc)
  );
  getLibraryFilterValuesForParamIdFunc$ = this._store.pipe(
    select(tilePageSelectors.getLibraryFilterValuesForParamIdFunc)
  );
  getLibraryForParamIdFunc$ = this._store.pipe(
    select(tilePageSelectors.getLibraryForParamIdFunc)
  );

  constructor(store: Store<AppState>) {
    super(store);
  }

  getTilePages$() {
    return this.dispatchAction$(getTilePages);
  }
  createTilePage$(page: TilePage) {
    return this.dispatchAction$(createTilePage, {
      page,
    });
  }
  updateTilePage$(page: TilePage) {
    return this.dispatchAction$(updateTilePage, {
      page,
    });
  }
  deleteTilePage(tilePageId: string) {
    this._store.dispatch(
      deleteTilePage({
        tilePageId,
      })
    );
  }
  createSegment$(segment: TilePageSegment, tilePageId: string) {
    return this.dispatchAction$(createSegment, {
      segment,
      tilePageId,
    });
  }
  updateSegment$(
    segment: TilePageSegment,
    tilePageChange?: { new: string; old: string }
  ) {
    return this.tilePagesForSegmentFunc$.pipe(
      first(),
      map((fn) => fn(segment.id).map((t) => t.id)),
      switchMap((tilePageIds) =>
        this.dispatchAction$(updateSegment, {
          segment,
          tilePageIds: tilePageChange
            ? [
                ...tilePageIds.filter((id) => id != tilePageChange.old),
                tilePageChange.new,
              ]
            : tilePageIds,
          previousTilePageIds: tilePageIds,
        })
      )
    );
  }
  updateSegments$(segments: TilePageSegment[], tilePageId: string) {
    if (!segments.length) return of([]);
    return this.dispatchAction$(updateSegments, {
      segments,
      tilePageId,
    });
  }
  deleteSegment(segmentId: string) {
    this._store.dispatch(
      deleteSegment({
        segmentId,
      })
    );
  }
  createTile$(tile: Tile, segmentId: string) {
    return this.dispatchAction$(createTile, {
      tile,
      segmentId,
    });
  }
  updateTile$(tile: Tile, segmentChange?: { new: string; old: string }) {
    return this.segmentsForTileFunc$.pipe(
      first(),
      map((fn) => fn(tile.id).map((t) => t.id)),
      switchMap((segmentIds) =>
        this.dispatchAction$(updateTile, {
          tile,
          segmentIds: segmentChange
            ? [
                ...segmentIds.filter((id) => id != segmentChange.old),
                segmentChange.new,
              ]
            : segmentIds,
          previousSegmentIds: segmentIds,
        })
      )
    );
  }
  updateTiles$(tiles: Tile[]) {
    if (!tiles.length) return of([]);
    return this.dispatchAction$(updateTiles, {
      tiles,
    });
  }
  deleteTile(tileId: string) {
    this._store.dispatch(
      deleteTile({
        tileId,
      })
    );
  }
  createTilePagePermission$(permission: Permission, tilePageId: string) {
    return this.dispatchAction$(createTilePagePermission, {
      permission,
      tilePageId,
    });
  }
  updateTilePagePermissions$(permissions: Permission[]) {
    return this.dispatchAction$(updateTilePagePermissions, {
      permissions,
    });
  }
  deleteTilePagePermissions$(permissionIds: string[]) {
    return this.dispatchAction$(deleteTilePagePermissions, {
      permissionIds,
    });
  }

  updateMetadataFilters$(
    tileId: string,
    previousFilters: MetadataFilter[],
    filters: MetadataFilter[]
  ) {
    return this.dispatchAction$(updateMetadataFilters, {
      tileId,
      previousFilters,
      filters,
    });
  }

  updateCurrentPage(page: TilePage) {
    this._store.dispatch(updateCurrentTilePage({ tilePage: page }));
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
