import { createAction, props } from '@ngrx/store';
import { DMSWebpartPermission } from '../../domain/models/dms-webpart-permission.model';
import { DMSWebpartInterestGroup } from '../../domain/models/dms-webpart-interestgroup.model';
import { ActionBase } from 'processdelight-angular-components';

export const getDMSWebpartPermissions = createAction(
  '[Metadata] Get DMS webpart permissions',
  props<ActionBase<DMSWebpartPermission[]>>()
);

export const getDMSWebpartPermissionsResolved = createAction(
  '[Metadata] Get DMS webpart permissions resolved',
  props<{
    permissions: DMSWebpartPermission[];
  }>()
);

export const createDMSWebpartPermissions = createAction(
  '[Metadata] Create DMS webpart permissions',
  props<
    {
      permissions: DMSWebpartPermission[];
    } & ActionBase<DMSWebpartPermission[]>
  >()
);

export const createDMSWebpartPermissionsResolved = createAction(
  '[Metadata] Create DMS webpart permissions resolved',
  props<{
    permissions: DMSWebpartPermission[];
  }>()
);

export const deleteDMSWebpartPermissions = createAction(
  '[Metadata] Delete DMS webpart permissions',
  props<
    {
      permissionIds: string[];
    } & ActionBase<string[]>
  >()
);

export const deleteDMSWebpartPermissionsResolved = createAction(
  '[Metadata] Delete DMS webpart permissions resolved',
  props<{
    permissionIds: string[];
  }>()
);

export const getDMSWebpartInterestGroups = createAction(
  '[Metadata] Get DMS webpart interest groups',
  props<ActionBase<DMSWebpartInterestGroup[]>>()
);

export const getDMSWebpartInterestGroupsResolved = createAction(
  '[Metadata] Get DMS webpart interest groups resolved',
  props<{
    interestGroups: DMSWebpartInterestGroup[];
  }>()
);

export const createDMSWebpartInterestGroups = createAction(
  '[Metadata] Create DMS webpart interest groups',
  props<
    {
      interestGroups: DMSWebpartInterestGroup[];
    } & ActionBase<DMSWebpartInterestGroup[]>
  >()
);

export const createDMSWebpartInterestGroupsResolved = createAction(
  '[Metadata] Create DMS webpart interest groups resolved',
  props<{
    interestGroups: DMSWebpartInterestGroup[];
  }>()
);

export const deleteDMSWebpartInterestGroups = createAction(
  '[Metadata] Delete DMS webpart interest groups',
  props<
    {
      interestGroupIds: string[];
    } & ActionBase<string[]>
  >()
);

export const deleteDMSWebpartInterestGroupsResolved = createAction(
  '[Metadata] Delete DMS webpart interest groups resolved',
  props<{
    interestGroupIds: string[];
  }>()
);

export const resetDMSMetadataSlice = createAction('[Metadata] Reset slice');
