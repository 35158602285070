<div class="d-flex flex-row h-100 w-100">
  <div
    class="w-100 h-100 d-flex flex-column position-relative flex-grow-1 min-width-0"
    (dragover)="dragStart($event)"
  >
    <div
      [hidden]="!dragging"
      class="position-absolute w-100 h-100 drop-zone"
      (drop)="filesDropped($event)"
      (dragover)="$event.preventDefault(); $event.stopPropagation()"
      (dragleave)="dragStop($event)"
    ></div>
    <input
      hidden
      type="file"
      multiple
      (change)="onFileInputChange($event)"
      #fileInput
    />
    <div
      class="d-flex flex-column w-100 p-3"
      *ngIf="metadataParams$ | async as params"
    >
      <div
        class="d-flex flex-row justify-content-between align-items-center w-100"
      >
        <div
          class="d-flex flex-row align-items-center"
          *ngIf="!useOldFilter; else oldFilter"
        >
          <button mat-icon-button (click)="toggleFiltering()">
            <mat-icon class="material-icons-outlined">
              filter_alt{{ filtering ? "_off" : "" }}
            </mat-icon>
          </button>
          <ng-container *ngIf="filtering">
            <mat-form-field
              appearance="outline"
              class="ms-2 me-2 no-subscript-wrapper"
            >
              <mat-label>{{ getTranslation$("filter") | async }}</mat-label>
              <mat-select
                (openedChange)="$event ? focusInput(searchInput) : undefined"
                panelClass="p-0"
                [formControl]="filterParamControl"
              >
                <div class="d-flex flex-column h-100">
                  <mat-form-field class="no-subscript-wrapper">
                    <mat-label
                      >{{ getTranslation$("search") | async }}...</mat-label
                    >
                    <input matInput type="text" #searchInput />
                  </mat-form-field>
                  <div class="overflow-auto scrollbar">
                    <mat-option
                      *ngFor="
                        let param of getFilteredParams(params);
                        trackBy: paramTrackBy
                      "
                      [value]="param"
                      [hidden]="
                        param.title.toLowerCase().indexOf(searchInput.value) ===
                        -1
                      "
                    >
                      {{ param.title }}
                    </mat-option>
                  </div>
                </div>
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="filterParamControl.value">
              <processdelight-adaptable-library-input
                class="no-subscript-wrapper"
                [param]="filterParamControl.value"
                [params]="params"
                [library]="library"
                [filter]="true"
                [formControl]="filterValueControl"
                (keydown.enter)="addFilter()"
                #filterValueElement
              ></processdelight-adaptable-library-input>
              <button
                mat-mini-fab
                color="primary"
                class="ms-2"
                *ngIf="
                  filterValue[filterParamControl.value.id] === undefined;
                  else filterExists
                "
                (click)="addFilter()"
                [disabled]="
                  filterValueControl.invalid || filterValueControl.pristine
                "
              >
                <mat-icon>search</mat-icon>
              </button>
              <ng-template #filterExists>
                <button
                  mat-mini-fab
                  color="primary"
                  class="ms-2"
                  (click)="addFilter()"
                >
                  <mat-icon>search</mat-icon>
                </button>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
        <ng-template #oldFilter>
          <div class="w-100" #oldFilterDiv>
            <processdelight-metadata-inputs
              class="w-100"
              [filter]="true"
              [edit]="true"
              [library]="library"
              [buttonTemplate]="buttonTemplate"
              [value]="filterValue"
              [previousValue]="oldFilterValue"
              (valueChanges)="oldFilterValue = $event"
              [maxHeight]="oldFilterMaxHeight + 'px'"
              #metadataInputs
            ></processdelight-metadata-inputs>
            <ng-template
              #buttonTemplate
              let-value="value"
              let-clearValue="clearValue"
            >
              <div class="w-100 d-flex flex-row justify-content-end">
                <button
                  mat-raised-button
                  color="primary"
                  class="me-2"
                  (click)="
                    updateFilter(oldFilterValue);
                    markFormAsPristine(metadataInputs)
                  "
                >
                  {{ getTranslation$("apply") | async }}
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="me-2"
                  (click)="
                    clearValue();
                    updateFilter({});
                    markFormAsPristine(metadataInputs)
                  "
                >
                  {{ getTranslation$("clear") | async }}
                </button>
              </div>
            </ng-template>
            <div
              class="w-100 d-flex justify-content-center mt-1 rounded"
              style="height: 0; border-top: 1px solid gray"
            >
              <mat-icon
                style="
                  background-color: #fafafa;
                  transform: translateY(-12px);
                  cursor: row-resize;
                "
                (mousedown)="
                  resizeStart(oldFilterDiv.getBoundingClientRect().top)
                "
                (touchstart)="
                  resizeStart(oldFilterDiv.getBoundingClientRect().top)
                "
                >drag_handle</mat-icon
              >
            </div>
          </div>
        </ng-template>
        <div
          class="d-flex"
          [ngClass]="
            this.selectedView === 'tileView'
              ? useOldFilter
                ? 'flex-column justify-content-between align-items-center'
                : 'flex-row align-items-center'
              : 'flex-row align-items-center'
          "
        >
          <ng-container *ngIf="page && !(filtering || oldFiltering)">
            <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
          </ng-container>
        </div>
      </div>
      <mat-chip-listbox *ngIf="!useOldFilter">
        <mat-chip
          *ngFor="let filter of filterValueArray; trackBy: filterTrackBy"
          (click)="selectFilter(params, filter.paramId, filter.value)"
        >
          {{ getParamById(params, filter.paramId)?.title }}:
          {{ getParamValue(params, filter.paramId, filter.value) }}
          <button matChipRemove (click)="removeFilter(filter.paramId)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>
    </div>
    <!-- TODO: Fix tiles above library -->
    <app-tile-page
      class="h-100 min-height-0"
      *ngIf="page && !(filtering || oldFiltering)"
      [page]="page"
    ></app-tile-page>
    <app-library
      *ngIf="filtering || oldFiltering"
      [library]="library"
      [filterValue]="filterValue"
      [buttonsTemplateRef]="buttonsTemplate"
      class="mh-100 flex-grow-1 min-height-0"
    ></app-library>
  </div>
  <processdelight-side-pane
    *ngIf="libraryComponent?.previewItem"
    [width]="getPreviewWidth()"
    [parentWidth]="bodyWidth"
    [positionAbsolute]="false"
    [dockRight]="dockRight"
    (closePane)="libraryComponent!.previewItem = undefined"
    (dockRightChange)="onDockRightChange($event)"
  >
    <app-item-preview
      [item]="libraryComponent!.previewItem!"
      (updated)="libraryComponent!.updateItem($event)"
      (deleted)="libraryComponent!.removeItem($event)"
      class="flex-grow-2"
    ></app-item-preview>
  </processdelight-side-pane>
</div>
<ng-template #buttonsTemplate>
  <div>
    <mat-button-toggle-group
      (change)="toggleFiltering()"
      [value]="selectedView"
    >
      <mat-button-toggle value="tileView">
        <mat-icon>apps</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="documentView">
        <mat-icon>format_list_bulleted</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <button
    *ngIf="filtering"
    mat-icon-button
    color="primary"
    (click)="refreshFilter()"
    class="ms-2"
  >
    <mat-icon class="material-icons-outlined">refresh</mat-icon>
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="fileInput.click()"
    class="ms-2 text-uppercase"
    [disabled]="!canUpload || appLibrary"
  >
    {{ getTranslation$("upload") | async }}
    <mat-icon class="material-icons-outlined">file_upload</mat-icon>
  </button>
</ng-template>
