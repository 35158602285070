import { Store } from '@ngrx/store';
import {
  ApplicationNotification,
  createLibrariesResolved,
  createLibraryItemsResolved,
  createMetadataParamsResolved,
  deleteLibraryItemsResolved,
  deleteLibraryResolved,
  deleteMetadataParamResolved,
  Library,
  LibraryItem,
  MetadataParam,
  SignalRService,
  updateLibraryItemsResolved,
  updateLibraryResolved,
  updateMetadataParamResolved,
  UserLicenseInfo,
} from 'processdelight-angular-components';
import { AppState } from 'src/app/app.reducer';
import { camelcaseKeys } from '../helper/object.functions';
import {
  createNotificationResolved,
  deleteNotificationResolved,
  updateNotificationResolved,
} from '../store/notification/notification.actions';
import {
  config$,
  externallyUpdatedLibraryItems$,
  license$,
} from '../data/data.observables';
import {
  deleteTileResolved,
  updateTileByIdResolved,
} from '../store/tilepage/tilepage.actions';
import { Tile } from '../domain/models/tile.model';
import { AppConfig } from '../domain/models/app-config.model';

export const StartServiceBusReceiver = (
  serviceBusReceiver: SignalRService,
  store: Store<AppState>
) => {
  serviceBusReceiver.subscribeToTopic<LibraryItem>('ishtardmsitem', {
    post: (items) =>
      store.dispatch(
        createLibraryItemsResolved({
          items: items.map((i) => new LibraryItem(camelcaseKeys(i))),
        })
      ),
    patch: (items) => {
      items = items.map((i) => new LibraryItem(camelcaseKeys(i)));
      store.dispatch(
        updateLibraryItemsResolved({
          items,
        })
      );
      externallyUpdatedLibraryItems$.next(items.map((i) => i.id));
    },
    delete: (items) =>
      store.dispatch(deleteLibraryItemsResolved({ itemIds: items })),
  });
  serviceBusReceiver.subscribeToTopic<Library>('ishtardmslibrary', {
    post: (items: Library[]) =>
      store.dispatch(createLibrariesResolved({ libraries: items })),
    patch: ([item]) =>
      store.dispatch(
        updateLibraryResolved({ library: new Library(camelcaseKeys(item)) })
      ),
    delete: ([libraryId]) =>
      store.dispatch(deleteLibraryResolved({ libraryId })),
  });
  serviceBusReceiver.subscribeToTopic<MetadataParam>(
    'ishtardmsmetadataparameter',
    {
      post: (items: MetadataParam[]) =>
        store.dispatch(
          createMetadataParamsResolved({
            metadataParams: items.map(
              (i) => new MetadataParam(camelcaseKeys(i))
            ),
          })
        ),
      patch: (items: MetadataParam[]) =>
        store.dispatch(
          updateMetadataParamResolved({
            metadataParams: items.map(
              (i) => new MetadataParam(camelcaseKeys(i))
            ),
          })
        ),
      delete: (items: string[]) =>
        store.dispatch(
          deleteMetadataParamResolved({ metadataParamIds: items })
        ),
    }
  );

  serviceBusReceiver.subscribeToTopic<ApplicationNotification>(
    'ishtarapplicationnotification',
    {
      post: ([item]) =>
        store.dispatch(
          createNotificationResolved({
            notification: new ApplicationNotification(camelcaseKeys(item)),
          })
        ),
      patch: ([item]) =>
        store.dispatch(
          updateNotificationResolved({
            notification: new ApplicationNotification(camelcaseKeys(item)),
          })
        ),
      delete: ([item]) =>
        store.dispatch(deleteNotificationResolved({ notificationId: item })),
    }
  );

  serviceBusReceiver.subscribeToTopic<UserLicenseInfo>('ishtar365licenses', {
    patch: ([item]) => license$.next(new UserLicenseInfo(camelcaseKeys(item))),
  });

  serviceBusReceiver.subscribeToTopic<AppConfig>('ishtarapplicationconfig', {
    patch: ([item]) => config$.next(new AppConfig(camelcaseKeys(item))),
  });

  serviceBusReceiver.subscribeToTopic<Tile>('ishtardmstile', {
    post: ([item]) => {
      // TODO.  segment id not passable so skip this one for now
    },
    patch: (items) =>
      store.dispatch(
        updateTileByIdResolved({
          tiles: items.map((i) => new Tile(camelcaseKeys(i))),
        })
      ),
    delete: ([item]) => store.dispatch(deleteTileResolved({ tileId: item })),
  });
};
