import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';

import { environment } from './environments/environment';

import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideStore } from '@ngrx/store';
import {
  StoreDevtoolsModule,
  provideStoreDevtools,
} from '@ngrx/store-devtools';
import {
  APP_STARTUP,
  DatacomponentService,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_DEFAULT_ICON,
  ISHTAR365_EMAIL_ICON,
  ISHTAR365_EXCEL_ICON,
  ISHTAR365_FUNCTIONS_KEY,
  ISHTAR365_FUNCTIONS_SCOPE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_INSTANCE,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_MOVIE_ICON,
  ISHTAR365_PDF_ICON,
  ISHTAR365_PICTURE_ICON,
  ISHTAR365_POWERPOINT_ICON,
  ISHTAR365_URL,
  ISHTAR365_WORD_ICON,
  LIBRARY_SERVICE_TOKEN,
  LOCALE_INJECTOR,
  METADATA_SERVICE_TOKEN,
  MY_FORMATS,
  MsalModule,
  MyDateAdapterOptions,
  PROJECTS_SERVICE_TOKEN,
  SessionExpiredModule,
  TASKS_SERVICE_TOKEN,
  TranslationService,
  microsoftAuthenticationInterceptor,
} from 'processdelight-angular-components';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { StartUpService } from './app/core/services/startup.service';
import { MetadataService } from './app/core/services/metadata.service';
import { LibraryService } from './app/core/services/library.service';
import { ProjectsService } from './app/core/services/projects.service';
import { TasksService } from './app/core/services/tasks.service';
import { ListService } from './app/core/services/list.service';

import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
if (environment.production) enableProdMode();

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: METADATA_SERVICE_TOKEN,
      useClass: MetadataService,
    },
    {
      provide: LIBRARY_SERVICE_TOKEN,
      useClass: LibraryService,
    },
    {
      provide: PROJECTS_SERVICE_TOKEN,
      useClass: ProjectsService,
    },
    {
      provide: TASKS_SERVICE_TOKEN,
      useClass: TasksService,
    },
    {
      provide: DatacomponentService,
      useClass: ListService,
    },
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    { provide: ISHTAR365_FUNCTIONS_KEY, useValue: environment.ishtarFunctionsKey },
    { provide: ISHTAR365_WORD_ICON, useValue: environment.ishtar_word_icon },
    { provide: ISHTAR365_POWERPOINT_ICON, useValue: environment.ishtar_powerpoint_icon },
    { provide: ISHTAR365_EXCEL_ICON, useValue: environment.ishtar_excel_icon },
    { provide: ISHTAR365_PDF_ICON, useValue: environment.ishtar_pdf_icon },
    { provide: ISHTAR365_PICTURE_ICON, useValue: environment.ishtar_picture_icon },
    { provide: ISHTAR365_MOVIE_ICON, useValue: environment.ishtar_movie_icon },
    { provide: ISHTAR365_EMAIL_ICON, useValue: environment.ishtar_email_icon },
    { provide: ISHTAR365_DEFAULT_ICON, useValue: environment.ishtar_default_icon },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    { provide: ISHTAR365_LAST_ROUTE_KEY, useValue: environment.lastRouteKey },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['page'] },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: MyDateAdapterOptions,
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: LOCALE_INJECTOR,
      deps: [TranslationService],
    },
    provideRouter(routes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule,
      MatNativeDateModule,
      SessionExpiredModule,
      HttpClientModule
    ),
    provideAnimations(),
  ],
});
