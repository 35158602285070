import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdditionUnit,
  ApplicationNotification,
  ChannelType,
  CreateNotificationRequest,
  dateTimesToISO,
  Day,
  Frequency,
  GroupUser,
  Language,
  Lookup,
  Month,
  Permission,
  PermissionType,
  Ranking,
  TriggerType,
  UserLicenseInfo,
  UserSettings,
} from 'processdelight-angular-components';
import { Observable, map, tap, timeout } from 'rxjs';
import { license$ } from '../data/data.observables';
import { AppConfig } from '../domain/models/app-config.model';
import { AppInfo } from '../domain/models/app-info.model';
import { SharedItem } from '../domain/models/shared-item.model';
import { TilePageSegment } from '../domain/models/tile-page-segment.model';
import { TilePage } from '../domain/models/tile-page.model';
import { Tile } from '../domain/models/tile.model';
import { camelcaseKeys } from '../helper/object.functions';
import { FunctionsService } from './functions.service';
import { PortalGroup } from '../domain/models/portal-group.model';
import { DMSWebpartPermission } from '../domain/models/dms-webpart-permission.model';
import { DMSWebpartInterestGroup } from '../domain/models/dms-webpart-interestgroup.model';
import { InterestGroup } from '../domain/models/interest-group.model';
import { IPreviousVersion } from '../domain/models/previous-version';

@Injectable({ providedIn: 'root' })
export class ApiService {
  apiBase = `${location.origin}/web`;
  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.apiBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/session/keepalive`, {});
  }

  getAppInfo(app: string) {
    return this.httpClient
      .get<AppInfo>(`${this.apiBase}/organization/app/${app}`)
      .pipe(map((info) => new AppInfo(camelcaseKeys(info))));
  }

  getTilePages() {
    return this.httpClient
      .get<TilePage[]>(`${this.apiBase}/tilepages`)
      .pipe(map((pages) => pages.map((p) => new TilePage(camelcaseKeys(p)))));
  }
  createTilePage(page: TilePage) {
    return this.httpClient
      .post<TilePage>(
        `${this.apiBase}/tilepages`,

        page
      )
      .pipe(map((tilePage) => new TilePage(camelcaseKeys(tilePage))));
  }
  updateTilePage(page: TilePage) {
    return this.httpClient
      .patch<TilePage>(
        `${this.apiBase}/tilepages`,

        {
          ...page,
          segments: null,
          permissions: null,
        }
      )
      .pipe(map((tilePage) => new TilePage(camelcaseKeys(tilePage))));
  }
  deleteTilePage(tilePageId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/${tilePageId}`
    );
  }

  createSegment(segment: TilePageSegment, tilePageId: string) {
    return this.httpClient
      .post<TilePageSegment>(`${this.apiBase}/tilepages/segments`, {
        ...segment,
        tilePageId,
      })
      .pipe(map((segment) => new TilePageSegment(camelcaseKeys(segment))));
  }
  updateSegment(
    segment: TilePageSegment,
    tilePageIds: string[],
    previousTilePageIds: string[]
  ) {
    return this.httpClient
      .patch<TilePageSegment>(
        `${this.apiBase}/tilepages/segments/${segment.id}`,
        { ...segment, tilePageIds, previousTilePageIds }
      )
      .pipe(map((segment) => new TilePageSegment(camelcaseKeys(segment))));
  }
  updateSegments(segments: TilePageSegment[]) {
    return this.httpClient
      .patch<TilePageSegment[]>(`${this.apiBase}/tilepages/segments`, segments)
      .pipe(
        map((segments) =>
          segments.map((segment) => new TilePageSegment(camelcaseKeys(segment)))
        )
      );
  }
  deleteSegment(segmentId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/segments/${segmentId}`
    );
  }

  createTile(tile: Tile, segmentId: string) {
    return this.httpClient
      .post<Tile>(`${this.apiBase}/tilepages/tiles`, {
        ...tile,
        segmentId,
      })
      .pipe(map((tile) => new Tile(camelcaseKeys(tile))));
  }
  updateTile(tile: Tile, segmentIds: string[], previousSegmentIds: string[]) {
    return this.httpClient
      .patch<Tile>(`${this.apiBase}/tilepages/tiles/${tile.id}`, {
        ...tile,
        segmentIds,
        previousSegmentIds,
      })
      .pipe(map((tile) => new Tile(camelcaseKeys(tile))));
  }
  updateTiles(tiles: Tile[]) {
    return this.httpClient
      .patch<Tile[]>(`${this.apiBase}/tilepages/tiles`, tiles)
      .pipe(map((tiles) => tiles.map((t) => new Tile(camelcaseKeys(t)))));
  }
  deleteTile(tileId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/tiles/${tileId}`
    );
  }

  createTilePagePermission(permission: Permission, tilePageId: string) {
    return this.httpClient
      .post<Permission>(`${this.apiBase}/tilepages/permissions`, {
        ...permission,
        tilePageId,
        permissionType: PermissionType[permission.permissionType],
      })
      .pipe(map((permission) => new Permission(camelcaseKeys(permission))));
  }
  updateTilePagePermissions(permissions: Permission[]) {
    return this.httpClient
      .patch<Permission[]>(
        `${this.apiBase}/tilepages/permissions`,
        permissions.map((p) => ({
          ...p,
          permissionType: PermissionType[p.permissionType],
        }))
      )
      .pipe(
        map((permissions) =>
          permissions.map((p) => new Permission(camelcaseKeys(p)))
        )
      );
  }
  deleteTilePagePermissions(permissionIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/permissions`,
      { body: permissionIds }
    );
  }

  public getPreviousVersions(sharepointFileId: string): Observable<IPreviousVersion[]> {
    return this.httpClient.get<IPreviousVersion[]>(
      `${this.apiBase}/documents/GetPreviousVersions?sharepointFileId=${sharepointFileId}`
    );
  }

  public getSelectedVersionOfDocument(
    sharepointFileId: string,
    versionLabel: string): Observable<HttpResponse<Blob>> {

    const headers = new HttpHeaders({ 'Accept': 'application/octet-stream' });
    const url = `${this.apiBase}/documents/GetSelectedVersionOfDocument`;
    const params = new HttpParams()
      .set('sharepointFileId', sharepointFileId)
      .set('versionLabel', versionLabel);
    
      return this.httpClient.get(url, {
        headers, 
        params,
        responseType: 'blob', 
        observe: 'response'
      });
  }

  public restoreVersion(
    sharepointFileId: string,
    versionLabel: string): Observable<boolean> {
    const params = new HttpParams()
      .set('sharepointFileId', sharepointFileId)
      .set('versionLabel', versionLabel);
    return this.httpClient.post<boolean>(`${this.apiBase}/documents/RestoreVersion`, {}, { params });
  }

  getSharedWithItems(itemId: string) {
    return this.httpClient
      .get<SharedItem[]>(`${this.apiBase}/documents/shared/${itemId}`)
      .pipe(map((m) => m.map((p) => new SharedItem(camelcaseKeys(p)))));
  }
  createSharedWithItem(items: SharedItem[]) {
    return this.httpClient
      .post<SharedItem[]>(`${this.apiBase}/documents/shared`, items)
      .pipe(map((m) => m.map((p) => new SharedItem(camelcaseKeys(p)))));
  }
  deleteSharedWithItem(itemId: string) {
    return this.httpClient.delete<string[]>(
      `${this.apiBase}/documents/shared/${itemId}`,
      { body: [itemId] }
    );
  }

  uploadTileIcon(fileName: string, blob: Blob) {
    const formData = new FormData();
    formData.append('file', blob, fileName);
    return this.httpClient
      .post<string>(`${this.apiBase}/tilepages/tileicons`, formData, {
        responseType: 'text' as 'json',
      })
      .pipe(
        map((relativeUrl) =>
          this.functionsService.getSPValueUrl(relativeUrl, true)
        )
      );
  }
  deleteTileIcon(relativeUrl: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/documents/tileicons?relativeurl=${relativeUrl}`
    );
  }

  getLanguages() {
    return this.httpClient
      .get<Language[]>(`${this.apiBase}/user/languages`)
      .pipe(map((ls) => ls.map((l) => new Language(camelcaseKeys(l)))));
  }
  getTranslations() {
    return this.httpClient.get<any>(
      `${this.apiBase}/user/translations/${license$.value!.language}`
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/organization/users`)
      .pipe(map((user) => user.map((u) => new GroupUser(camelcaseKeys(u)))));
  }
  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/organization/groups`)
      .pipe(map((group) => group.map((g) => new GroupUser(camelcaseKeys(g)))));
  }
  getConfiguration() {
    return this.httpClient
      .get<AppConfig>(`${this.apiBase}/organization/config`)
      .pipe(map((c) => new AppConfig(camelcaseKeys(c))));
  }
  updateConfiguration(config: AppConfig) {
    return this.httpClient
      .post<AppConfig>(`${this.apiBase}/organization/config`, config)
      .pipe(map((c) => new AppConfig(camelcaseKeys(c))));
  }
  getUserSettings() {
    return this.httpClient
      .get<UserSettings>(`${this.apiBase}/user/settings`)
      .pipe(map((c) => new UserSettings(camelcaseKeys(c))));
  }
  updateUserSettings(settings: UserSettings) {
    return this.httpClient
      .post<UserSettings>(`${this.apiBase}/user/settings`, settings)
      .pipe(map((c) => new UserSettings(camelcaseKeys(c))));
  }

  getBlob(url: string, timeoutAfter = 10000) {
    let pipe;
    if (timeoutAfter > 0)
      pipe = timeout({
        first: timeoutAfter,
      });
    else pipe = tap(() => undefined);
    return this.httpClient
      .get<Blob>(url, { responseType: 'blob' as 'json' })
      .pipe(pipe) as Observable<Blob>;
  }

  getTaskTemplates() {
    return this.httpClient
      .get<Lookup[]>(`${this.apiBase}/libraries/taskTemplates`)
      .pipe(map((c) => c.map((t) => new Lookup(camelcaseKeys(t)))));
  }

  // notifications

  getNotifications() {
    return this.httpClient
      .get<ApplicationNotification[]>(`${this.apiBase}/notifications`)
      .pipe(
        map((notifications) =>
          notifications.map(
            (n) => new ApplicationNotification(camelcaseKeys(n))
          )
        )
      );
  }
  getNotificationById(notificationId: string) {
    return this.httpClient
      .get<ApplicationNotification>(
        `${this.apiBase}/notifications/${notificationId}`
      )
      .pipe(map((n) => new ApplicationNotification(camelcaseKeys(n))));
  }
  createNotification(notification: CreateNotificationRequest) {
    return this.httpClient
      .post<ApplicationNotification>(
        `${this.apiBase}/notifications`,
        dateTimesToISO(notification)
      )
      .pipe(map((n) => new ApplicationNotification(camelcaseKeys(n))));
  }
  updateNotification(notification: CreateNotificationRequest) {
    return this.httpClient
      .patch<ApplicationNotification>(
        `${this.apiBase}/notifications`,
        dateTimesToISO(notification)
      )
      .pipe(map((n) => new ApplicationNotification(camelcaseKeys(n))));
  }
  deleteNotification(notificationId: string) {
    return this.httpClient.delete<string>(
      `${this.apiBase}/notifications/${notificationId}`,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  getNotificationFrequencies() {
    return this.httpClient
      .get<Frequency[]>(`${this.apiBase}/notifications/frequencies`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Frequency(camelcaseKeys(n)))
        )
      );
  }

  getNotificationTriggerTypes() {
    return this.httpClient
      .get<TriggerType[]>(`${this.apiBase}/notifications/triggertypes`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new TriggerType(camelcaseKeys(n)))
        )
      );
  }

  getNotificationAdditionUnits() {
    return this.httpClient
      .get<AdditionUnit[]>(`${this.apiBase}/notifications/additionunits`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new AdditionUnit(camelcaseKeys(n)))
        )
      );
  }

  getNotificationChannelTypes() {
    return this.httpClient
      .get<ChannelType[]>(`${this.apiBase}/notifications/channeltypes`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new ChannelType(camelcaseKeys(n)))
        )
      );
  }

  getDays() {
    return this.httpClient
      .get<Day[]>(`${this.apiBase}/notifications/days`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Day(camelcaseKeys(n)))
        )
      );
  }

  getMonths() {
    return this.httpClient
      .get<Month[]>(`${this.apiBase}/notifications/months`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Month(camelcaseKeys(n)))
        )
      );
  }

  getRankings() {
    return this.httpClient
      .get<Ranking[]>(`${this.apiBase}/notifications/rankings`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Ranking(camelcaseKeys(n)))
        )
      );
  }

  getPortalGroups() {
    return this.httpClient
      .get<PortalGroup[]>(`${this.apiBase}/portal/groups`)
      .pipe(map((group) => group.map((g) => new PortalGroup(g))));
  }

  getDMSWebpartPermissions() {
    return this.httpClient
      .get<DMSWebpartPermission[]>(
        `${this.apiBase}/metadata/webpart/permissions`
      )
      .pipe(
        map((permissions) =>
          permissions.map((p) => new DMSWebpartPermission(p))
        )
      );
  }
  createDMSWebpartPermissions(permissions: DMSWebpartPermission[]) {
    return this.httpClient
      .post<DMSWebpartPermission[]>(
        `${this.apiBase}/metadata/webpart/permissions`,
        permissions
      )
      .pipe(map((ps) => ps.map((p) => new DMSWebpartPermission(p))));
  }
  deleteDMSWebpartPermissions(permissionIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/metadata/webpart/permissions`,
      { body: permissionIds }
    );
  }
  getInterestGroups() {
    return this.httpClient
      .get<InterestGroup[]>(`${this.apiBase}/metadata/webpart/interestGroups`)
      .pipe(
        map((interestGroups) => interestGroups.map((i) => new InterestGroup(i)))
      );
  }
  getDMSWebpartInterestGroups() {
    return this.httpClient
      .get<DMSWebpartInterestGroup[]>(
        `${this.apiBase}/metadata/webpart/webpartInterestGroups`
      )
      .pipe(map((groups) => groups.map((g) => new DMSWebpartInterestGroup(g))));
  }

  createDMSWebpartInterestGroups(interestGroups: DMSWebpartInterestGroup[]) {
    return this.httpClient
      .post<DMSWebpartInterestGroup[]>(
        `${this.apiBase}/metadata/webpart/webpartInterestGroups`,
        interestGroups
      )
      .pipe(map((ps) => ps.map((p) => new DMSWebpartInterestGroup(p))));
  }
  deleteDMSWebpartInterestGroups(interestGroupIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/metadata/webpart/webpartInterestGroups`,
      { body: interestGroupIds }
    );
  }
}
