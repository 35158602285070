import { Action, createReducer, on } from '@ngrx/store';
import {
  createDMSWebpartPermissionsResolved,
  createDMSWebpartInterestGroupsResolved,
  deleteDMSWebpartPermissionsResolved,
  deleteDMSWebpartInterestGroupsResolved,
  getDMSWebpartPermissionsResolved,
  getDMSWebpartInterestGroupsResolved,
  resetDMSMetadataSlice,
} from './dms-metadata.actions';
import { DMSWebpartPermission } from '../../domain/models/dms-webpart-permission.model';
import { DMSWebpartInterestGroup } from '../../domain/models/dms-webpart-interestgroup.model';

export const featureSlice = 'dmsMetadata';

export interface State {
  dmsWebpartPermissions: DMSWebpartPermission[];
  dmsWebpartInterestGroups: DMSWebpartInterestGroup[];
}

const defaultState: State = {
  dmsWebpartPermissions: [],
  dmsWebpartInterestGroups: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return dmsMetadataReducer(state, action);
}

export const initialState: State = defaultState;
export const dmsMetadataReducer = createReducer(
  initialState,
  on(getDMSWebpartPermissionsResolved, (state, { permissions }) => ({
    ...state,
    dmsWebpartPermissions: permissions,
  })),
  on(createDMSWebpartPermissionsResolved, (state, { permissions }) => ({
    ...state,
    dmsWebpartPermissions: [...state.dmsWebpartPermissions, ...permissions],
  })),
  on(deleteDMSWebpartPermissionsResolved, (state, { permissionIds }) => ({
    ...state,
    dmsWebpartPermissions: state.dmsWebpartPermissions.filter(
      (p) => !permissionIds.some((id) => id === p.id)
    ),
  })),
  on(getDMSWebpartInterestGroupsResolved, (state, { interestGroups }) => ({
    ...state,
    dmsWebpartInterestGroups: interestGroups,
  })),
  on(createDMSWebpartInterestGroupsResolved, (state, { interestGroups }) => ({
    ...state,
    dmsWebpartInterestGroups: [
      ...state.dmsWebpartInterestGroups,
      ...interestGroups,
    ],
  })),
  on(deleteDMSWebpartInterestGroupsResolved, (state, { interestGroupIds }) => ({
    ...state,
    dmsWebpartInterestGroups: state.dmsWebpartInterestGroups.filter(
      (p) => !interestGroupIds.some((id) => id === p.id)
    ),
  })),
  on(resetDMSMetadataSlice, () => initialState)
);
