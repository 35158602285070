import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { BaseFacade } from '../base.facade';
import {
  createDMSWebpartInterestGroups,
  createDMSWebpartPermissions,
  deleteDMSWebpartInterestGroups,
  deleteDMSWebpartPermissions,
  getDMSWebpartPermissions,
  resetDMSMetadataSlice,
} from './dms-metadata.actions';
import { dmsMetadataSelectors } from './dms-metadata.selectors';
import { DMSWebpartPermission } from '../../domain/models/dms-webpart-permission.model';
import { getDMSWebpartInterestGroups } from './dms-metadata.actions';
import { DMSWebpartInterestGroup } from '../../domain/models/dms-webpart-interestgroup.model';

@Injectable({
  providedIn: 'root',
})
export class DMSMetadataFacade extends BaseFacade {
  dmsWebpartPermissions$ = this._store.pipe(
    select(dmsMetadataSelectors.getDMSWebpartPermissions)
  );
  dmsWebpartInterestGroups$ = this._store.pipe(
    select(dmsMetadataSelectors.getDMSWebpartInterestGroups)
  );
  getDMSWebpartPermissions$() {
    return this.dispatchAction$(getDMSWebpartPermissions);
  }
  createDMSWebpartPermissions$(permissions: DMSWebpartPermission[]) {
    return this.dispatchAction$(createDMSWebpartPermissions, {
      permissions,
    });
  }
  deleteDMSWebpartPermissions$(permissionIds: string[]) {
    return this.dispatchAction$(deleteDMSWebpartPermissions, {
      permissionIds,
    });
  }
  getDMSWebpartInterestGroups$() {
    return this.dispatchAction$(getDMSWebpartInterestGroups);
  }
  createDMSWebpartInterestGroups$(interestGroups: DMSWebpartInterestGroup[]) {
    return this.dispatchAction$(createDMSWebpartInterestGroups, {
      interestGroups,
    });
  }
  deleteDMSWebpartInterestGroups$(interestGroupIds: string[]) {
    return this.dispatchAction$(deleteDMSWebpartInterestGroups, {
      interestGroupIds,
    });
  }
  resetSlice() {
    this._store.dispatch(resetDMSMetadataSlice());
  }
}
