import { GroupUser, LibraryItem } from 'processdelight-angular-components';
import { BaseEntity } from 'processdelight-angular-components';

export class SharedItem extends BaseEntity {
  itemId!: string;
  item!: LibraryItem;
  fromId!: string;
  from!: GroupUser;
  toId!: string;
  to!: GroupUser;

  constructor(obj?: Partial<SharedItem>) {
    super(obj ?? {});
    Object.assign(this, obj);
    if (obj?.from) this.from = new GroupUser(obj.from);
    if (obj?.to) this.to = new GroupUser(obj.to);
    if (obj?.item) this.item = new LibraryItem(obj.item);
  }
}
