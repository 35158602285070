import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

const metadataState = (state: AppState) => state.coreFeature.dmsMetadata;

const getDMSWebpartPermissions = createSelector(
  metadataState,
  (state) => state.dmsWebpartPermissions
);

const getDMSWebpartInterestGroups = createSelector(
  metadataState,
  (state) => state.dmsWebpartInterestGroups
);

export const dmsMetadataSelectors = {
  getDMSWebpartPermissions,
  getDMSWebpartInterestGroups,
};
