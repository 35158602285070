import { DateTime } from 'luxon';
import { GroupUser, MetadataParam } from 'processdelight-angular-components';
import { BaseEntity } from 'processdelight-angular-components';
import { Tile } from './tile.model';

export class MetadataFilter extends BaseEntity {
  value!: string | GroupUser;
  metadataParameterId!: string;
  metadataParameter!: MetadataParam;
  tileId!: string;
  tile!: Tile;

  constructor(obj: Partial<MetadataFilter>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.value && typeof obj.value == 'string')
      try {
        const val: any = JSON.parse(obj.value);
        if ('start' in val || 'end' in val) {
          if ('start' in val)
            val.start = DateTime.fromISO(val.start, { zone: 'utc' });
          if ('end' in val)
            val.end = DateTime.fromISO(val.end, { zone: 'utc' });
          this.value = val;
        } else if ('group' in val || 'user' in val) {
          this.value = new GroupUser(val);
        } else this.value = obj.value;
      } catch {
        this.value = obj.value;
      }
    if (obj.metadataParameter)
      this.metadataParameter = new MetadataParam(obj.metadataParameter);
    if (obj.tile) this.tile = new Tile(obj.tile);
  }
}
