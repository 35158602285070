import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { combineLatest, first, tap } from 'rxjs';
import { appRoutes, routes } from 'src/app/app.routes';
import { PageComponent } from 'src/app/page/page.component';
import { TileAction } from '../domain/enums/tile-action.enum';
import { TilePage } from '../domain/models/tile-page.model';
import { TilePageFacade } from '../store/tilepage/tilepage.facade';
import { Library, LibraryFacade, MicrosoftAuthenticationService } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class RouteBuilderService {
  constructor(
    private msal: MicrosoftAuthenticationService,
    private router: Router,
    private tilePageFacade: TilePageFacade,
    private libraryFacade: LibraryFacade
  ) {}

  buildTilePageRoutes(
    route: Route,
    page: TilePage,
    pages: TilePage[],
    libraries: Library[]
  ) {
    const routes: Routes = [];
    const libRoutes: Routes = [];
    page.segments
      .map((s) => s.tilePageSegment)
      .filter((s) => !s.disabled)
      .forEach((s) =>
        s.tiles
          .map((t) => t.tile)
          .filter((t) => !t.disabled)
          .forEach((t) => {
            if (t.tileActionType == TileAction.TilePage) {
              const page = pages.find((p) => p.id == t.tileAction);
              if (
                page &&
                (!page.permissions?.length ||
                  page.permissions.some(({ permission }) =>
                    permission.groupUser.user
                      ? permission.groupUser.id == this.msal.userId
                      : permission.groupUser.group?.members?.some(
                          (m) => m.id == this.msal.userId
                        )
                  ))
              ) {
                const childRoute: Route = {
                  path: route.path + '/' + page.title,
                  component: PageComponent,
                  children: [],
                  data: {
                    breadcrumbTitle: page.title,
                    breadcrumbParent: route,
                    tilePageId: page.id,
                    filters: {},
                  },
                };
                routes.push(childRoute);
                routes.push(
                  ...this.buildTilePageRoutes(
                    childRoute,
                    page,
                    pages,
                    libraries
                  )
                );
              }
            } else if (
              t.tileActionType == TileAction.LibraryFilter &&
              t.tileAction
            ) {
              const library = libraries.find((l) => l.id == t.tileAction);
              if (
                library &&
                (!library.permissions?.length ||
                  library.permissions.some(({ permission }) =>
                    permission.groupUser.user
                      ? permission.groupUser.id == this.msal.userId
                      : permission.groupUser.group?.members?.some(
                          (m) => m.id == this.msal.userId
                        )
                  ))
              ) {
                const existingRoute = libRoutes.find(
                  (r) => r.data?.libraryId == library.id
                );
                if (existingRoute) {
                  if (existingRoute.data)
                    existingRoute.data.filters = {
                      ...existingRoute.data.filters,
                      [t.id]: t.metadataFilters,
                    };
                } else {
                  const childRoute: Route = {
                    path: route.path + '/' + library.title,
                    component: PageComponent,
                    children: [],
                    data: {
                      breadcrumbTitle: library.title,
                      breadcrumbParent: route,
                      libraryId: library.id,
                      filters: {
                        [t.id]: t.metadataFilters,
                      },
                    },
                  };
                  libRoutes.push(childRoute);
                  routes.push(childRoute);
                }
              }
            } else if (t.tileActionType == TileAction.LibraryFilter)
              if (route.data?.filters)
                route.data.filters = {
                  ...route.data.filters,
                  [t.id]: t.metadataFilters,
                };
          })
      );
    return routes;
  }

  initRoutes() {
    return combineLatest([
      this.tilePageFacade.tilePages$,
      this.libraryFacade.libraries$,
    ]).pipe(
      first(),
      tap(([pages, libraries]) => {
        const r = [...appRoutes, ...routes];
        const homepage = pages.find((p) => p.homepage);
        if (homepage) {
          const defaultRoute: Route = {
            path: 'page',
            redirectTo: 'page/' + homepage.title,
          };
          const route: Route = {
            path: 'page/' + homepage.title,
            component: PageComponent,
            children: [],
            data: {
              breadcrumbTitle: homepage.title,
              tilePageId: homepage.id,
              filters: {},
            },
          };
          r.unshift(defaultRoute);
          r.unshift(
            ...this.buildTilePageRoutes(route, homepage, pages, libraries)
          );
          r.unshift(route);
        }
        const fallback = r.find((r) => r.path == '**');
        if (fallback) fallback.redirectTo = '404';
        this.router.resetConfig(r);
      })
    );
  }

  resetRoutes() {
    this.router.resetConfig(routes);
  }
}
