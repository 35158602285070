import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import camelcaseKeys from "camelcase-keys";
import { DashboardComponent, Filter, ITasksService, Task } from "processdelight-angular-components";
import { catchError, map, Observable, throwError } from "rxjs";

@Injectable()
export class TasksService implements ITasksService {
    private readonly apiBase = `${location.origin}/web`;
    private readonly httpClient = inject(HttpClient);

    public getTasks(
        assignedToMe: boolean,
        orderBy: string,
        direction: string,
        filters: Filter[],
        pageSize: number,
        page: number
    ): Observable<{ result: Task[]; totalRecordCount: number; }> {
        
        const filter = DashboardComponent.createFilterString(filters);
        let url = `${this.apiBase}/task?assignedToMe=${assignedToMe}&orderBy=${orderBy}&direction=${direction}&pageSize=${pageSize}&page=${page}`;
        if (filter !== '') url += `&filter=${filter}`;
        
        return this.httpClient.get<{ result: Task[]; totalRecordCount: number; }>(url)
        .pipe(map((response) => camelcaseKeys(response)),
            catchError((error) =>  throwError(() => error)));
  }

  public getTaskById(id: string): Observable<Task> {
    return this.httpClient.get<Task>(`${this.apiBase}/task/${id}`)
      .pipe(map((task) => new Task(task)),
        catchError((error) => throwError(() => error)));
  }
}