import { BaseEntity } from 'processdelight-angular-components';

export class DMSWebpartInterestGroup extends BaseEntity {
  interestGroupId!: string;
  metadataParameterId!: string;
  metadataChoiceId?: string;

  constructor(data: Partial<DMSWebpartInterestGroup>) {
    super(data);
    Object.assign(this, data);
  }
}
