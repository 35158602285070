import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { CRMEntity, DatacomponentService, EntityObject } from "processdelight-angular-components";
import { map, Observable } from "rxjs";

@Injectable()
export class ListService extends DatacomponentService {
    private readonly apiBase = `${location.origin}/web`;
    private readonly httpClient = inject(HttpClient);

    public override getEntities(
        pageSize: number,
        page: number,
        sortedColumn: string,
        sortDirection: string,
        internalSort: boolean,
        internalFilterString: string,
        dataFilterString: string
      ): Observable<{
        result: EntityObject<CRMEntity>[];
        pagingCookie: string;
        totalRecordCount: number;
      }> {
        return this.httpClient
          .get<{
            result: EntityObject<CRMEntity>[];
            pagingCookie: string;
            totalRecordCount: number;
          }>(
            `${
              this.apiBase
            }/CRMEntity?pageSize=${pageSize}&page=${page}${this.orderByQuery(
              internalSort,
              sortedColumn,
              sortDirection
            )}&internalFilter=${internalFilterString}&dataFilter=${dataFilterString}`
          )
          .pipe(
            map(({ result, pagingCookie, totalRecordCount }) => ({
              result: result.map(
                (c) =>
                  new EntityObject<CRMEntity>({
                    ...c,
                    entity: new CRMEntity(c.entity ?? {}),
                  })
              ),
              pagingCookie,
              totalRecordCount,
            }))
          );
    }

    private orderByQuery(internalSort: boolean, column: string, direction: string) {
      if (!column || !direction) return '';
      else
        return (
          (internalSort ? '&internalOrderBy' : '&dataOrderBy') +
          `=${column}&orderByDirection=${direction.toUpperCase()}`
        );
    }
}