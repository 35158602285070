import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { dateTimesToISO, GroupUser, ILibraryService, IUploadResult, Library, LibraryItem, LibraryTrigger, Permission, PermissionType } from "processdelight-angular-components";
import { FunctionsService } from "./functions.service";
import { catchError, map, Observable, of, tap } from "rxjs";
import { camelcaseKeys } from "../helper/object.functions";
import { DateTime } from "luxon";
import * as uuid from 'uuid';

@Injectable()
export class LibraryService implements ILibraryService {
    private readonly apiBase = `${location.origin}/web`;
    private readonly httpClient = inject(HttpClient);
    private readonly functionsService = inject(FunctionsService);

    getLibraries() {
        return this.httpClient
        .get<Library[]>(`${this.apiBase}/libraries`)
        .pipe(map((m) => m.map((p) => new Library(camelcaseKeys(p)))));
    }
    
    createLibrary(library: Library) {
        return this.httpClient
          .post<Library>(`${this.apiBase}/libraries`, {
            ...library,
            configuredParams: library.configuredParams.map((c) => {
              let value = c.defaultValue;
              if (value) {
                if (Array.isArray(value)) {
                  value = JSON.stringify(
                    value.map((v) => {
                      if (v instanceof GroupUser) {
                        return {
                          user: v.user
                            ? { id: v.id, displayName: v.user.displayName }
                            : undefined,
                          group: v.group
                            ? {
                                groupId: v.group.groupId,
                                displayName: v.group.displayName,
                              }
                            : undefined,
                        };
                      }
                      return v;
                    })
                  );
                } else if (value instanceof GroupUser) {
                  value = JSON.stringify({
                    user: value.user ? { id: value.id } : undefined,
                    group: value.group
                      ? {
                          groupId: value.group.groupId,
                          displayName: value.group.displayName,
                        }
                      : undefined,
                  });
                } else if (value instanceof Date) {
                  value = DateTime.fromJSDate(value).toISO() ?? '';
                } else if (typeof value == 'object') {
                  value = JSON.stringify(value);
                } else {
                  value = value?.toString() ?? '';
                }
              }
              return {
                ...c,
                defaultValue: value,
              };
            }),
          })
          .pipe(map((m) => new Library(camelcaseKeys(m))));
    }

    updateLibrary(library: Library) {
        return this.httpClient
          .patch<Library>(`${this.apiBase}/libraries`, {
            ...library,
            configuredParams: library.configuredParams.map((c) => {
              let value = c.defaultValue;
              if (value) {
                if (Array.isArray(value)) {
                  value = JSON.stringify(
                    value.map((v) => {
                      if (v instanceof GroupUser) {
                        return {
                          user: v.user
                            ? { id: v.id, displayName: v.user.displayName }
                            : undefined,
                          group: v.group
                            ? {
                                groupId: v.group.groupId,
                                displayName: v.group.displayName,
                              }
                            : undefined,
                        };
                      }
                      return v;
                    })
                  );
                } else if (value instanceof GroupUser) {
                  value = JSON.stringify({
                    user: value.user ? { id: value.id } : undefined,
                    group: value.group
                      ? {
                          groupId: value.group.groupId,
                          displayName: value.group.displayName,
                        }
                      : undefined,
                  });
                } else if (value instanceof DateTime) {
                  value =
                    value.toUTC(0, { keepLocalTime: true }).toISO() ?? undefined;
                } else if (typeof value == 'object') {
                  value = JSON.stringify(value);
                } else {
                  value = value?.toString() ?? undefined;
                }
              }
              return {
                ...c,
                defaultValue: value,
              };
            }),
          })
          .pipe(map((m) => new Library(camelcaseKeys(m))));
    }

    deleteLibrary(libraryId: string) {
        return this.httpClient.delete<boolean>(
            `${this.apiBase}/libraries/${libraryId}`
        );
    }

    getLibraryItems(
        orderBy: string | undefined,
        orderByDirection: 'asc' | 'desc' | undefined,
        filters: { [key: string]: string },
        pageSize: number,
        page: number,
        libraryId: string | undefined = undefined
      ) {
        return this.httpClient
          .get<{ result: LibraryItem[]; totalRecordCount: number }>(
            `${this.apiBase}/documents?pageSize=${pageSize}&page=${page}${
              orderBy ? '&orderBy=' + orderBy : ''
            }${
              orderByDirection ? '&orderByDirection=' + orderByDirection : ''
            }${this.parseFilters(filters)}${
              libraryId ? `&libraryId=${libraryId}` : ''
            }`
          )
          .pipe(
            map(({ result, totalRecordCount }) => ({
              result: result.map((p) => new LibraryItem(camelcaseKeys(p))),
              totalRecordCount,
            }))
          );
    }

    createLibraryItems(items: LibraryItem[]) {
        return this.httpClient
          .post<LibraryItem[]>(
            `${this.apiBase}/documents`,
            dateTimesToISO(items)
          )
          .pipe(map((m) => m.map((p) => new LibraryItem(camelcaseKeys(p)))));
    }

    updateLibraryItemsForMove(items: LibraryItem[]) {
        return this.httpClient
          .patch<LibraryItem[]>(
            `${this.apiBase}/documents/moveUpdate`,
            dateTimesToISO(items)
          )
          .pipe(map((m) => m.map((p) => new LibraryItem(camelcaseKeys(p)))));
    }

    updateLibraryItems(items: LibraryItem[]) {
        return this.httpClient
          .patch<LibraryItem[]>(
            `${this.apiBase}/documents`,
            dateTimesToISO(items)
          )
          .pipe(map((m) => m.map((p) => new LibraryItem(camelcaseKeys(p)))));
    }

    deleteLibraryItems(itemIds: string[]) {
        return this.httpClient.delete<boolean>(`${this.apiBase}/documents`, {
            body: itemIds,
        });
    }

    createLibraryPermission(permission: Permission, libraryId: string) {
        return this.httpClient
          .post<Permission>(`${this.apiBase}/libraries/permissions`, {
            ...permission,
            libraryId,
            permissionType: PermissionType[permission.permissionType],
        })
        .pipe(map((permission) => new Permission(camelcaseKeys(permission))));
    }

    updateLibraryPermissions(permissions: Permission[]) {
        return this.httpClient
          .patch<Permission[]>(
            `${this.apiBase}/libraries/permissions`,
            permissions.map((p) => ({
              ...p,
              permissionType: PermissionType[p.permissionType],
            }))
          )
          .pipe(
            map((permissions) =>
              permissions.map((p) => new Permission(camelcaseKeys(p)))
            )
        );
    }
    deleteLibraryPermissions(permissionIds: string[]) {
        return this.httpClient.delete<boolean>(
            `${this.apiBase}/libraries/permissions`,
            { body: permissionIds }
        );
    }

    getLibraryTriggers() {
        return this.httpClient
          .get<LibraryTrigger[]>(`${this.apiBase}/libraries/libraryTriggers`)
          .pipe(map((c) => c.map((t) => new LibraryTrigger(camelcaseKeys(t)))));
    }

    getSharedItems(
        orderBy: string | undefined,
        orderByDirection: 'asc' | 'desc' | undefined,
        filters: { [key: string]: string },
        pageSize: number,
        page: number
      ) {
        return this.httpClient
          .get<{ result: LibraryItem[]; totalRecordCount: number }>(
            `${this.apiBase}/documents/shared?pageSize=${pageSize}&page=${page}${
              orderBy ? '&orderBy=' + orderBy : ''
            }${
              orderByDirection ? '&orderByDirection=' + orderByDirection : ''
            }${this.parseFilters(filters)}`
          )
          .pipe(
            map(({ result, totalRecordCount }) => ({
              result: result.map((p) => new LibraryItem(camelcaseKeys(p))),
              totalRecordCount,
            }))
          );
    }

    checkFileName(libraryName: string, fileName: string) {
        return this.httpClient.post<boolean>(
          `${this.apiBase}/documents/checkFileName('${fileName}')?relativeUrl=${libraryName}`,
          {}
        );
    }

    checkUploadedFiles(fileName: string, libraryId?: string) {
        return this.httpClient
          .post<LibraryItem | undefined>(
            `${this.apiBase}/documents/checkUploaded('${fileName}')?${
              libraryId ? `libraryId=${libraryId}` : ''
            }`,
            {}
          )
          .pipe(
            map((m) => (m ? new LibraryItem(camelcaseKeys(m)) : undefined)),
            catchError(() => of(undefined))
          );
    }

    public uploadLibraryFile(fileName: string, blob: Blob, relativeUrl?: string): Observable<IUploadResult> {
        const formData = new FormData();
        formData.append('file', blob, fileName);
        formData.append('relativeUrl', relativeUrl ?? 'IshtarDMSLibraryItems');
        
        return this.httpClient.post<IUploadResult>(
            `${this.apiBase}/documents/upload`, formData)
          .pipe(tap((result) => result.fileLocation = this.functionsService
            .getFileLocation(result.relativeUrl)));
    }
  
    public uploadFileChunk(
        fileName: string,
        relativeUrl: string | undefined,
        blob: Blob,
        start?: number,
        end?: number,
        uploadId?: string
    ): Observable<IUploadResult> {
        const formData = new FormData();
        formData.append('file', blob, fileName);
        formData.append('relativeUrl', relativeUrl ?? 'IshtarDMSLibraryItems');
        formData.append('start', start?.toString() ?? '0');
        formData.append('end', end?.toString() ?? blob.size.toString());
        if (uploadId) formData.append('uploadId', uploadId);
        
        return this.httpClient.post<IUploadResult>(
            `${this.apiBase}/documents/uploadChunk`, formData)
          .pipe(tap((result) => result.fileLocation = this.functionsService
            .getFileLocation(result.relativeUrl)));
    }

    parseFilters = (filters: {
        [key: string]: string | { start: DateTime; end: DateTime } | GroupUser;
      }) => {
        const result =
          '&filter=' +
          Object.keys(filters)
            .map((key) => {
              if (typeof filters[key] == 'string') {
                if (uuid.validate(filters[key] as string))
                  return `(${key} eq ${filters[key]})`;
                else
                  return `(${key} in '${encodeURIComponent(
                    filters[key] as string
                  )}')`;
              } else if (typeof filters[key] == 'object') {
                if (filters[key] instanceof GroupUser) {
                  const val = filters[key] as GroupUser;
                  return `(${key} eq ${val.id})`;
                } else {
                  const val = filters[key] as { start: DateTime; end: DateTime };
                  let dateFilter = '';
                  if (val.start)
                    dateFilter += `(${key} ge ${val.start
                      .toUTC(0, { keepLocalTime: true })
                      .toISO()})`;
                  if (val.start && val.end) dateFilter += ',';
                  if (val.end)
                    dateFilter += `(${key} le ${val.end
                      .toUTC(0, { keepLocalTime: true })
                      .toISO()})`;
                  return dateFilter;
                }
              } else if (typeof filters[key] == 'number') {
                return `(${key} eq ${filters[key]})`;
              }
              return undefined;
            })
            .filter((f) => !!f)
            .join(',');
        return result;
    };

    checkLibraryExists(relativeUrl: string) {
      return this.httpClient.post<boolean>(
        `${this.apiBase}/libraries/checkExisting('${relativeUrl}')`,
        {}
      );
    }
  
    getLibraryFilePreview(sharepointId: string) {
      return this.httpClient.get<string>(
        `${this.apiBase}/documents/${sharepointId}/preview`,
        { responseType: 'text' as 'json' }
      );
    }
  
    getLibraryFileLocation(sharepointId: string) {
      return this.httpClient.get<string>(
        `${this.apiBase}/documents/${sharepointId}/location`,
        { responseType: 'text' as 'json' }
      );
    }

    getLibraryFileShareUrl(
      sharepointId: string,
      edit = false,
      expiry: Date | undefined = undefined
    ) {
      return this.httpClient.get<{
        shareLink: string;
        anonymousEnabled: boolean;
      }>(
        `${this.apiBase}/documents/${sharepointId}/share?edit=${edit}${
          expiry ? '&expiry=' + expiry?.toISOString() : ''
        }`
      );
    }

    getLibraryFileDesktopAppUrl(sharepointId: string, edit = false) {
      return this.httpClient.get<string>(
        `${this.apiBase}/documents/${sharepointId}/desktopApp?edit=${edit}`,
        {
          responseType: 'text' as 'json',
        }
      );
    }
    
    moveLibraryFile(sharepointId: string, newLibraryName: string) {
      const formData = new FormData();
      formData.append('fileId', sharepointId);
      formData.append('newLibraryName', newLibraryName);
      return this.httpClient.post<string>(
        `${this.apiBase}/documents/move`,
        formData,
        { responseType: 'text' as 'json' }
      );
    }

    copyLibraryFile(sharepointId: string, newFileName: string) {
      const formData = new FormData();
      formData.append('fileId', sharepointId);
      formData.append('newFileName', newFileName);
      return this.httpClient.post<string>(
        `${this.apiBase}/documents/copy`,
        formData,
        { responseType: 'text' as 'json' }
      );
    }
    
    deleteLibraryFile(sharepointId: string) {
      return this.httpClient.delete<boolean>(
        `${this.apiBase}/documents/${sharepointId}`
      );
    }
}