import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { dateTimesToISO, IMetadataService, Metadata, MetadataChoice, MetadataChoiceTranslation, MetadataParam, MetadataPermission, MetadataPermissionConfiguration } from "processdelight-angular-components";
import { filter, map } from "rxjs";
import { camelcaseKeys } from "../helper/object.functions";
import { MetadataFilter } from "../domain/models/metadata-filter.model";

@Injectable()
export class MetadataService implements IMetadataService {
    private readonly apiBase = `${location.origin}/web`;
    private readonly httpClient = inject(HttpClient);
    
    getMetadataParams(getAll = false) {
        return this.httpClient
          .get<MetadataParam[]>(
            `${this.apiBase}/metadata/parameters?getAll=${getAll}`
          )
          .pipe(map((params) => params.map((p) => new MetadataParam(p))));
    }
    
    createMetadataParam(metadataParam: MetadataParam) {
        return this.httpClient
          .post<MetadataParam>(`${this.apiBase}/metadata/parameters`, metadataParam)
          .pipe(map((param) => new MetadataParam(param)));
    }
    
    updateMetadataParam(metadataParam: MetadataParam) {
        return this.httpClient
          .patch<MetadataParam>(
            `${this.apiBase}/metadata/parameters`,
            metadataParam
          )
          .pipe(map((param) => new MetadataParam(param)));
    }
    
    deleteMetadataParam(metadataParamId: string) {
        return this.httpClient.delete<boolean>(
          `${this.apiBase}/metadata/parameters/${metadataParamId}`
        );
    }

    getMetadataPermissionConfigurations() {
        return this.httpClient
          .get<MetadataPermissionConfiguration[]>(
            `${this.apiBase}/metadata/permissions/configuration`
          )
          .pipe(
            map((permissions) =>
              permissions.map(
                (p) => new MetadataPermissionConfiguration(camelcaseKeys(p))
              )
            )
          );
    }

    updateMetadataPermissions(permissions: MetadataPermission[]) {
        return this.httpClient
          .patch<MetadataPermission[]>(
            `${this.apiBase}/metadata/permissions`,
            permissions
          )
          .pipe(
            filter(Boolean),
            map((permissions) =>
              permissions.map((p) => new MetadataPermission(camelcaseKeys(p)))
            )
          );
    }
    
    updateMetadataPermissionConfiguration(
        permissionConfig: MetadataPermissionConfiguration
      ) {
        return this.httpClient
          .post<MetadataPermissionConfiguration>(
            `${this.apiBase}/metadata/permissions/configuration`,
            permissionConfig
          )
          .pipe(
            filter(Boolean),
            map(
              (permissionConfig) =>
                new MetadataPermissionConfiguration(camelcaseKeys(permissionConfig))
            )
          );
    }

    createMetadataChoice(choice: MetadataChoice) {
        return this.httpClient
          .post<MetadataChoice>(`${this.apiBase}/metadata/choices`, {
            ...choice,
          })
          .pipe(map((p) => new MetadataChoice(camelcaseKeys(p))));
    }
    
    updateMetadataChoice(choice: MetadataChoice) {
        return this.httpClient
          .patch<MetadataChoice>(
            `${this.apiBase}/metadata/choices/${choice.id}`,
            choice
          )
          .pipe(map((choice) => new MetadataChoice(camelcaseKeys(choice))));
    }

    updateMetadataChoices(choices: MetadataChoice[]) {
        return this.httpClient
          .patch<MetadataChoice[]>(
            `${this.apiBase}/metadata/choices`,
            choices.map((choice) => ({ ...choice, translations: undefined }))
          )
          .pipe(
            map((p) => p.map((choice) => new MetadataChoice(camelcaseKeys(choice))))
          );
    }

    deleteMetadataChoice(paramId: string, choiceId: string) {
        return this.httpClient.delete<boolean>(
          `${this.apiBase}/metadata/choices/${paramId}/${choiceId}`
        );
    }

    createMetadataChoiceTranslations(translations: MetadataChoiceTranslation[]) {
        return this.httpClient
          .post<MetadataChoiceTranslation[]>(
            `${this.apiBase}/metadata/choicetranslations`,
            translations
          )
          .pipe(
            map((p) =>
              p.map((t) => new MetadataChoiceTranslation(camelcaseKeys(t)))
            )
          );
    }
    
    checkExistingMetadata(
        metadata: Metadata[],
        fileExtension: string,
        excludedId?: string,
        libraryId?: string
    ) {
        return this.httpClient.post<string | undefined>(
          `${this.apiBase}/metadata/checkExisting${
            libraryId ? `?libraryId=${libraryId}` : ''
          }`,
          {
            metadata: dateTimesToISO(metadata),
            fileExtension,
            excludedId,
          },
          { responseType: 'text' as 'json' }
        );
    }

    removeMetadataPermissions(permissionIds: string[]) {
        return this.httpClient.delete<boolean>(
          `${this.apiBase}/metadata/permissions`,
          { body: permissionIds }
        );
    }
    
    createMetadataFilter(metadata: MetadataFilter[]) {
        return this.httpClient
          .post<MetadataFilter[]>(
            `${this.apiBase}/metadata/filters`,
            dateTimesToISO(metadata)
          )
          .pipe(map((m) => m.map((p) => new MetadataFilter(camelcaseKeys(p)))));
    }
    
    updateMetadataFilter(metadata: MetadataFilter[]) {
        return this.httpClient
          .patch<MetadataFilter[]>(
            `${this.apiBase}/metadata/filters`,
            dateTimesToISO(metadata)
          )
          .pipe(map((m) => m.map((p) => new MetadataFilter(camelcaseKeys(p)))));
    }

    deleteMetadataFilter(filterIds: string[]) {
        return this.httpClient.delete<boolean>(`${this.apiBase}/metadata/filters`, {
          body: filterIds,
        });
    }
    
    createMetadata(metadata: Metadata[]) {
        return this.httpClient
          .post<Metadata[]>(
            `${this.apiBase}/metadata`,
            dateTimesToISO(metadata)
          )
          .pipe(map((m) => m.map((p) => new Metadata(camelcaseKeys(p)))));
    }

    updateMetadata(metadata: Metadata[]) {
        return this.httpClient
          .patch<Metadata[]>(
            `${this.apiBase}/metadata`,
            dateTimesToISO(metadata)
          )
          .pipe(map((m) => m.map((p) => new Metadata(camelcaseKeys(p)))));
    }

    deleteMetadata(filterIds: string[]) {
        return this.httpClient.delete<boolean>(`${this.apiBase}/metadata`, {
          body: filterIds,
        });
    }

    addMetadataPermissions(permissions: MetadataPermission[]) {
        return this.httpClient
          .post<MetadataPermission[]>(
            `${this.apiBase}/metadata/permissions`,
            permissions
          )
          .pipe(
            filter(Boolean),
            map((permissions) =>
              permissions.map((p) => new MetadataPermission(camelcaseKeys(p)))
            )
          );
    }
}