import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromCoreFeature from './store/core.feature';
import { DMSMetadataEffects } from './store/dms-metadata/dms-metadata.effects';
import { TilePageEffects } from './store/tilepage/tilepage.effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationEffects } from './store/notification/notification.effects';
import { EntityEffects, LibraryEffects, MetadataEffects } from 'processdelight-angular-components';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature([
      TilePageEffects,
      DMSMetadataEffects,
      NotificationEffects,
      MetadataEffects,
      LibraryEffects,
      EntityEffects,
    ]),

    MatSnackBarModule,
  ],
  exports: [CommonModule],
  declarations: [],
})
export class CoreModule {}
