import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AdaptableLibraryInputComponent, Library, LibraryFacade, LibraryIconService, LibraryItem, MetadataFacade, MetadataFilterType, MetadataParam, MicrosoftAuthenticationService, Permission, PermissionType } from 'processdelight-angular-components';
import { Subject, combineLatest, first, map, takeUntil } from 'rxjs';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { MoveItemsDialogComponent } from '../move-items-dialog/move-items-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { translations$ } from 'src/app/core/data/data.observables';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-edit-items-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    AdaptableLibraryInputComponent,
    MatCheckboxModule,
  ],
  templateUrl: './edit-items-dialog.component.html',
  styleUrls: ['./edit-items-dialog.component.scss'],
})
export class EditItemsDialogComponent implements OnInit, OnDestroy {
  confirmAction!: (
    items: LibraryItem[],
    value: { [key: string]: MetadataFilterType | undefined },
    overwriteInfo: { [key: string]: boolean }
  ) => void;
  items: LibraryItem[] = [];
  itemsWithNoPermissions: LibraryItem[] = [];
  library?: Library;

  params: MetadataParam[] = [];

  paramArray = new FormArray<
    FormGroup<{
      param: FormControl<string | null>;
      value: FormControl<MetadataFilterType | null>;
      resettingValueField: FormControl<boolean | null>;
      overwrite: FormControl<boolean | null>;
    }>
  >([]);

  paramTrackBy = (_i: number, param: MetadataParam) => param.id;

  get formValid() {
    return !this.paramArray.invalid;
  }

  fileNameMap: { [key: string]: string } = {};

  destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<MoveItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      confirmAction: (
        items: LibraryItem[],
        value: { [key: string]: MetadataFilterType | undefined }
      ) => void;
      items: LibraryItem[];
      library?: Library;
    },
    private readonly metadataFacade: MetadataFacade,
    private readonly libraryFacade: LibraryFacade,
    private readonly tilePageFacade: TilePageFacade,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly libraryIconService: LibraryIconService,
    private readonly domSanitizer: DomSanitizer
  ) {}

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  getEditingXDocumentsTranslation$() {
    return this.getTranslation$('editingXDocuments').pipe(
      map((t) => t.replace('{{length}}', this.items.length.toString()))
    );
  }

  ngOnInit(): void {
    this.confirmAction = this.data.confirmAction;
    this.library = this.data.library;
    combineLatest([
      this.libraryFacade.libraries$.pipe(first()),
      this.tilePageFacade.homePage$.pipe(first()),
      this.metadataFacade.metadataParams$.pipe(first()),
    ]).subscribe(([libs, homepage, params]) => {
      this.params = this.library
        ? params.filter((p) =>
            this.library?.configuredParams.some((c) => c.paramId == p.id)
          )
        : params.filter(
            (p) =>
              !p.fileNameParam &&
              !p.modifiedByParam &&
              !p.modifiedOnParam &&
              !p.createdByParam &&
              !p.createdOnParam
          );
      this.params = [...this.params].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      const fileNameParam = params.find((p) => p.fileNameParam);
      this.data.items.forEach((item) => {
        this.fileNameMap[item.id] =
          item.metadata.find((m) => m.metadataParameter.id == fileNameParam?.id)
            ?.value ?? '';
        let permissions: Permission[] = [];
        if (item.library)
          permissions =
            libs
              .find((l) => l.id == item.library?.id)
              ?.permissions.map((p) => p.permission) ?? [];
        else permissions = homepage?.permissions.map((p) => p.permission) ?? [];
        if (
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.permissionType != PermissionType.Read
          )
        ) {
          this.items.push(item);
        } else {
          this.itemsWithNoPermissions.push(item);
        }
      });
      this.addParam();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getIcon(item: LibraryItem) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.libraryIconService.provideIcon(item.fileLocation)!
    );
  }

  getParam(
    group: FormGroup<{
      param: FormControl<string | null>;
      value: FormControl<MetadataFilterType | null>;
      resettingValueField: FormControl<boolean | null>;
      overwrite: FormControl<boolean | null>;
    }>
  ) {
    return group.get('param')?.value
      ? this.params.find((p) => p.id == group.get('param')?.value)
      : undefined;
  }

  getValue(
    group: FormGroup<{
      param: FormControl<string | null>;
      value: FormControl<MetadataFilterType | null>;
      resettingValueField: FormControl<boolean | null>;
      overwrite: FormControl<boolean | null>;
    }>
  ) {
    return group.get('value')?.value;
  }

  getResettingValueField(
    group: FormGroup<{
      param: FormControl<string | null>;
      value: FormControl<MetadataFilterType | null>;
      resettingValueField: FormControl<boolean | null>;
      overwrite: FormControl<boolean | null>;
    }>
  ) {
    return group.get('resettingValueField')?.value;
  }

  getOverwrite(
    group: FormGroup<{
      param: FormControl<string | null>;
      value: FormControl<MetadataFilterType | null>;
      resettingValueField: FormControl<boolean | null>;
      overwrite: FormControl<boolean | null>;
    }>
  ) {
    return group.get('overwrite')?.value;
  }

  addParam(index?: number) {
    const group = new FormGroup({
      param: new FormControl<string | null>(null, Validators.required),
      value: new FormControl<MetadataFilterType | null>(null),
      resettingValueField: new FormControl<boolean>(false),
      overwrite: new FormControl<boolean>(true),
    });
    if (index == undefined) this.paramArray.push(group);
    else this.paramArray.insert(index + 1, group);
    group
      .get('param')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        group.get('resettingValueField')?.setValue(true);
        group.get('value')?.setValue(null);
        if (this.getParam(group)?.required)
          group.get('value')?.setValidators(Validators.required);
        else group.get('value')?.clearValidators();
        setTimeout(() => {
          group.get('resettingValueField')?.setValue(false);
        }, 0);
      });
  }

  removeParam(index: number) {
    if (this.paramArray.length > 1) this.paramArray.removeAt(index);
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }

  confirm() {
    if (this.formValid) {
      this.confirmAction(
        this.items,
        this.paramArray.value.reduce((acc, curr) => {
          return { ...acc, [curr.param!]: curr.value ?? undefined };
        }, {} as { [key: string]: MetadataFilterType | undefined }),
        this.paramArray.value.reduce((acc, curr) => {
          return { ...acc, [curr.param!]: curr.overwrite ?? false };
        }, {} as { [key: string]: boolean })
      );
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
