import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap, tap } from 'rxjs';
import { translations$ } from '../../data/data.observables';
import { ApiService } from '../../services/api.service';
import {
  createDMSWebpartPermissions,
  createDMSWebpartPermissionsResolved,
  createDMSWebpartInterestGroups,
  createDMSWebpartInterestGroupsResolved,
  deleteDMSWebpartPermissions,
  deleteDMSWebpartPermissionsResolved,
  deleteDMSWebpartInterestGroups,
  deleteDMSWebpartInterestGroupsResolved,
  getDMSWebpartPermissions,
  getDMSWebpartPermissionsResolved,
  getDMSWebpartInterestGroups,
  getDMSWebpartInterestGroupsResolved,
} from './dms-metadata.actions';

@Injectable({ providedIn: 'root' })
export class DMSMetadataEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private snackbar: MatSnackBar,
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }

  getDMSWebpartPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(getDMSWebpartPermissions),
      switchMap(({ callback, error }) =>
        this.api.getDMSWebpartPermissions().pipe(
          tap((permissions) => (callback ? callback(permissions) : undefined)),
          switchMap((permissions) =>
            of(getDMSWebpartPermissionsResolved({ permissions }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  createDMSWebpartPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(createDMSWebpartPermissions),
      mergeMap(({ permissions, callback, error }) =>
        this.api.createDMSWebpartPermissions(permissions).pipe(
          tap((permissions) => (callback ? callback(permissions) : undefined)),
          mergeMap((permissions) =>
            of(createDMSWebpartPermissionsResolved({ permissions }))
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorCreateWebpartPermissions'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );

  deleteDMSWebpartPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDMSWebpartPermissions),
      mergeMap(({ permissionIds, callback, error }) =>
        this.api.deleteDMSWebpartPermissions(permissionIds).pipe(
          tap(() => (callback ? callback(permissionIds) : undefined)),
          mergeMap(() =>
            of(
              deleteDMSWebpartPermissionsResolved({
                permissionIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorDeleteWebpartPermissions'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
  getDMSWebpartInterestGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(getDMSWebpartInterestGroups),
      switchMap(({ callback, error }) =>
        this.api.getDMSWebpartInterestGroups().pipe(
          tap((interestGroups) =>
            callback ? callback(interestGroups) : undefined
          ),
          switchMap((interestGroups) =>
            of(getDMSWebpartInterestGroupsResolved({ interestGroups }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  createDMSWebpartInterestGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(createDMSWebpartInterestGroups),
      mergeMap(({ interestGroups, callback, error }) =>
        this.api.createDMSWebpartInterestGroups(interestGroups).pipe(
          tap((interestGroups) =>
            callback ? callback(interestGroups) : undefined
          ),
          mergeMap((interestGroups) =>
            of(createDMSWebpartInterestGroupsResolved({ interestGroups }))
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorCreateWebpartPermissions'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );

  deleteDMSWebpartInterestGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDMSWebpartInterestGroups),
      mergeMap(({ interestGroupIds, callback, error }) =>
        this.api.deleteDMSWebpartInterestGroups(interestGroupIds).pipe(
          tap(() => (callback ? callback(interestGroupIds) : undefined)),
          mergeMap(() =>
            of(
              deleteDMSWebpartInterestGroupsResolved({
                interestGroupIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorDeleteWebpartInterestGroups'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
}
