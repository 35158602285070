import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, firstValueFrom, map, of, Subject, takeUntil, tap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { translations$ } from 'src/app/core/data/data.observables';
import { ApiService } from 'src/app/core/services/api.service';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { IPreviousVersion } from 'src/app/core/domain/models/previous-version';
import { MatButtonModule } from '@angular/material/button';
import { CapitalizeFirstPipe, LibraryItem } from 'processdelight-angular-components';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-previous-versions-dialog',
  templateUrl: './previous-versions-dialog.component.html',
  styleUrls: ['./previous-versions-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    CapitalizeFirstPipe,
    MatSnackBarModule,
    MatTooltipModule,
    MatPaginatorModule,
  ],
})
export class PreviousVersionsDialogComponent implements OnInit, OnDestroy {
  private readonly item: LibraryItem;
  public readonly title: string;
  public isLoadingVersions = true;
  public isOpeningSelectedDoc = false;
  public isRestoringVersion = false;
  public readonly translations = translations$.value;
  public readonly dataSource = new MatTableDataSource<IPreviousVersion>();
  @ViewChild(MatTable) table: MatTable<IPreviousVersion> | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  private readonly destroy$ = new Subject<void>();
  public readonly displayedColumns = [
    'versionLabel', 'createdAt', 'actions'
  ] as const;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { item: LibraryItem, label: string },
    public dialogRef: MatDialogRef<PreviousVersionsDialogComponent>,
    private apiService: ApiService,
    private snackbar: MatSnackBar) {
    this.item = data.item;
    const titleWithoutExtension = this.item.title
      .split('.').slice(0, -1).join('.');
    this.title = data.label + ' - ' + titleWithoutExtension;
  }

  ngOnInit(): void {
    void this.fetchPreviousVersions();
  }

  private async fetchPreviousVersions(): Promise<void> {    
    await firstValueFrom(this.apiService
      .getPreviousVersions(this.item.sharepointId).pipe(
        takeUntil(this.destroy$),
        map((versions) => versions.sort((a, b) => b.createdAt.localeCompare(a.createdAt))),
        tap((versions) => this.dataSource.data.push(...versions)),
        tap(() => this.dataSource.paginator = this.paginator ?? null),
        tap(() => this.table?.renderRows()),
        finalize(() => this.isLoadingVersions = false),
        catchError((e) => {
          this.snackbar.open(
            "Failed to fetch previous versions",
            'OK', { duration: 3000 });
          console.error(e);
          return of(void 0);
        })
      ));
  }
  
  public async openVersion(version: IPreviousVersion): Promise<void> {
    this.isOpeningSelectedDoc = true;
    const sharepointId = this.item.sharepointId;

    await firstValueFrom(this.apiService
      .getSelectedVersionOfDocument(sharepointId, version.versionLabel).pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          if (!response.body) return;
          const url = window.URL.createObjectURL(response.body);
          const a = document.createElement('a');
          a.href = url;
          a.download = this.item.title;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }),
        finalize(() => this.isOpeningSelectedDoc = false),
        catchError((e) => {
          this.snackbar.open(
            "Failed to open version",
            'OK', { duration: 3000 });
          console.error(e);
          return of(void 0);
        })
      ));
  }

  public async restoreVersion(version: IPreviousVersion): Promise<void> {
    this.isRestoringVersion = true;
    await firstValueFrom(this.apiService
      .restoreVersion(this.item.sharepointId, version.versionLabel).pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.snackbar.open(
            "Version restored successfully",
            'OK', { duration: 3000 });
            this.dialogRef.close();
        }),
        finalize(() => this.isRestoringVersion = false),
        catchError((e) => {
          this.snackbar.open(
            "Failed to restore version",
            'OK', { duration: 3000 });
          console.error(e);
          return of(void 0);
        })
      ));
  }

  public static async open(
    deleteDialog: MatDialog,
    item: LibraryItem,
    label: string,
    config?: MatDialogConfig<unknown>): Promise<void> {
    config ??= {};
    config.data = { item, label };
    await firstValueFrom(
      deleteDialog
      .open(this, {
        autoFocus: false,
        disableClose: true,
        minWidth: "50%",
        panelClass: 'previous-versions-dialog',
        ...config,
      })
      .afterClosed());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
