import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromTilePage from './tilepage/tilepage.reducer';
import * as fromDMSMetadata from './dms-metadata/dms-metadata.reducer';
import * as fromNotification from './notification/notification.reducer';
import {
  entityFeatureSlice,
  entityReducer,
  EntityState,
  libraryFeatureSlice,
  libraryReducer,
  LibraryState,
  metadataFeatureSlice,
  metadataReducer,
  MetadataState
} from 'processdelight-angular-components';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromTilePage.featureSlice]: fromTilePage.State;
  [fromDMSMetadata.featureSlice]: fromDMSMetadata.State;
  [fromNotification.featureSlice]: fromNotification.State;
  [metadataFeatureSlice]: MetadataState;
  [libraryFeatureSlice]: LibraryState;
  [entityFeatureSlice]: EntityState;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromTilePage.featureSlice]: fromTilePage.Reducer,
      [fromDMSMetadata.featureSlice]: fromDMSMetadata.Reducer,
      [fromNotification.featureSlice]: fromNotification.Reducer,
      [metadataFeatureSlice]: metadataReducer,
      [libraryFeatureSlice]: libraryReducer,
      [entityFeatureSlice]: entityReducer,
    }),
  }
);
