import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { DateTime } from 'luxon';
import {
  ColumnType,
  DataRecordGlance,
  MetadataFilterRecord,
  GroupUser,
  isIshtarAppParam,
  Library,
  LIBRARY_SERVICE_TOKEN,
  LibraryFacade,
  LibraryItem,
  LibraryParamConfig,
  Metadata,
  MetadataFacade,
  MetadataInputsComponent,
  MicrosoftAuthenticationService,
  Notification,
  NotificationService,
  NotificationType,
  Permission,
  PermissionType,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  delay,
  filter,
  first,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import {
  externallyUpdatedLibraryItems$,
  translations$,
  users$,
} from 'src/app/core/data/data.observables';
import {
  LibraryDocumentService,
  PreviewType,
} from 'src/app/core/services/library-document.service';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { DeleteItemsDialogComponent } from '../delete-items-dialog/delete-items-dialog.component';
import { DataModifiedPopupComponent } from 'src/app/core/components/data-modified-popup/data-modified-popup.component';
import { LibraryService } from 'src/app/core/services/library.service';

@Component({
  selector: 'app-item-preview',
  standalone: true,
  imports: [
    CoreModule,
    MetadataInputsComponent,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
  ],
  templateUrl: './item-preview.component.html',
  styleUrls: ['./item-preview.component.scss'],
})
export class ItemPreviewComponent implements OnChanges, OnInit, OnDestroy {
  @Input() item!: LibraryItem;

  @Output() updated = new EventEmitter<LibraryItem>();
  @Output() deleted = new EventEmitter<{ reloadPage: boolean }>();
  @ViewChild('videoPlayer', { static: false }) videoPlayer!: ElementRef;

  initialValue: MetadataFilterRecord = {};
  itemValue: MetadataFilterRecord = {};

  previewType?: PreviewType;
  previewData?: SafeResourceUrl | SafeHtml;

  PreviewTypes = PreviewType;

  updating = false;

  redrawing = false;

  library?: Library;

  formValid = new BehaviorSubject<boolean>(false);
  formDisabled = false;
  destroy$ = new Subject<void>();
  permissions: Permission[] = [];

  get canEdit() {
    return (
      !this.permissions.length ||
      this.permissions.some(
        (p) =>
          (p.groupUser.user?.id == this.msal.userId ||
            p.groupUser.group?.members?.some(
              (m) => m.id == this.msal.userId
            )) &&
          p.permissionType != PermissionType.Read
      )
    );
  }
  get canDelete() {
    return (
      !this.permissions.length ||
      this.permissions.some(
        (p) =>
          (p.groupUser.user?.id == this.msal.userId ||
            p.groupUser.group?.members?.some(
              (m) => m.id == this.msal.userId
            )) &&
          p.deletePermission
      )
    );
  }

  constructor(
    private cd: ChangeDetectorRef,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly tilePageFacade: TilePageFacade,
    private readonly libraryFacade: LibraryFacade,
    @Inject(LIBRARY_SERVICE_TOKEN) private readonly libraryService: LibraryService,
    private readonly metadataFacade: MetadataFacade,
    private readonly sanitizer: DomSanitizer,
    private readonly matDialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly libraryDocumentService: LibraryDocumentService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    externallyUpdatedLibraryItems$
      .pipe(
        takeUntil(this.destroy$),
        filter((items) => items.some((i) => i === this.item.id))
      )
      .subscribe(() => {
        this.matDialog
          .open(DataModifiedPopupComponent, {
            data: {
              showCancel: false,
              msg: this.getTranslation$('itemChanged'),
              confirmText: this.getTranslation$('closeItem'),
            },
          })
          .afterClosed()
          .subscribe((confirm) => {
            if (confirm) this.deleted.next({ reloadPage: false });
          });
      });
  }

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.item &&
      changes.item.currentValue != changes.item.previousValue
    ) {
      if (changes.item.currentValue?.id != changes.item.previousValue?.id) {
        if (!changes.item.firstChange) {
          this.previewData = undefined;
          this.previewType = undefined;
        }
        this.libraryDocumentService
          .providePreviewUrl(
            this.item.sharepointId,
            this.item.fileLocation.split('.').pop()!
          )
          .subscribe(({ data, type }) => {
            this.previewData =
              type == PreviewType.Email
                ? this.sanitizeHtml(data)
                : this.transform(data);
            this.previewType = type;
            this.videoPlayer.nativeElement.load();
            this.cd.detectChanges();
          });
      }

      this.redrawing = true;
      this.itemValue = {};
      this.initialValue = {};
      this.metadataFacade.metadataParams$.pipe(first()).subscribe((params) => {
        this.item.metadata.forEach((m) => {
          const param = params.find((p) => p.id == m.metadataParameter.id);
          if (param?.multi)
            if (this.itemValue[m.metadataParameter.id]) {
              if (param.type == ColumnType.GroupUser)
                this.itemValue[m.metadataParameter.id] = [
                  ...(this.itemValue[m.metadataParameter.id] as GroupUser[]),
                  m.groupUserValue!,
                ];
              else
                this.itemValue[m.metadataParameter.id] = [
                  ...(this.itemValue[m.metadataParameter.id] as string[]),
                  m.value!,
                ];
            } else {
              if (param.type == ColumnType.GroupUser)
                this.itemValue[m.metadataParameter.id] = [m.groupUserValue!];
              else this.itemValue[m.metadataParameter.id] = [m.value!];
            }
          else
            this.itemValue[m.metadataParameter.id] = (m.dateTimeValue ??
              m.numberValue ??
              m.groupUserValue ??
              m.value)!;
        });
        this.initialValue = { ...this.itemValue };
        setTimeout(() => {
          this.redrawing = false;
        }, 0);
      });
      if (this.item.libraryId)
        this.libraryFacade
          .libraryById$(this.item.libraryId)
          .pipe(first())
          .subscribe((l) => {
            this.library = new Library({
              ...l,
              configuredParams:
                l?.configuredParams.map(
                  (p) =>
                    new LibraryParamConfig({ ...p, choices: [...p.choices] })
                ) ?? [],
            });
            this.permissions = l?.permissions.map((p) => p.permission) ?? [];
            this.formDisabled = !this.canEdit;
          });
      else
        this.tilePageFacade.homePage$.pipe(first()).subscribe((p) => {
          this.library = undefined;
          this.permissions = p?.permissions.map((p) => p.permission) ?? [];
          this.formDisabled = !this.canEdit;
        });
    }
  }

  updateFormValid(valid: boolean) {
    setTimeout(() => {
      this.formValid.next(valid);
    }, 0);
  }

  dirty = false;

  metadataChanges(value: MetadataFilterRecord) {
    this.itemValue = value;
    this.dirty = true;
  }

  updateItem() {
    this.updating = true;
    const existing = this.item.metadata;
    combineLatest([this.metadataFacade.metadataParams$, users$])
      .pipe(first())
      .subscribe(([params, users]) => {        
        const newMetadata = Object.entries(this.itemValue)
          .filter(([_, v]) => v != undefined)
          .reduce((acc, [k, v]) => {
            const param = params.find((p) => p.id == k);
            if (Array.isArray(v)) {
              if (param?.type == ColumnType.GroupUser)
                return [
                  ...acc,
                  ...v.map(
                    (v) =>
                      new Metadata({
                        metadataParameterId: param.id,
                        itemId: this.item.id,
                        groupUserValue: v as any,
                      })
                  ),
                ];
              return [
                ...acc,
                ...v.map(
                  (v) =>
                    new Metadata({
                      metadataParameterId: param?.id,
                      itemId: this.item.id,
                      value: v as any,
                    })
                ),
              ];
            }
            if (param?.type == ColumnType.DateTime) {
              if (param.modifiedOnParam)
                return [
                  ...acc,
                  new Metadata({
                    id: existing.find((e) => e.metadataParameter.id == k)?.id,
                    metadataParameterId: param.id,
                    itemId: this.item.id,
                    dateTimeValue: DateTime.now(),
                  }),
                ];
              else
                return [
                  ...acc,
                  new Metadata({
                    id: existing.find((e) => e.metadataParameter.id == k)?.id,
                    metadataParameterId: param.id,
                    itemId: this.item.id,
                    dateTimeValue: v as DateTime,
                  }),
                ];
            } else if (param?.type == ColumnType.Number)
              return [
                ...acc,
                new Metadata({
                  id: existing.find((e) => e.metadataParameter.id == k)?.id,
                  metadataParameterId: param.id,
                  itemId: this.item.id,
                  numberValue: v as any,
                }),
              ];
            else if (param?.type == ColumnType.GroupUser)
              return [
                ...acc,
                new Metadata({
                  id: existing.find((e) => e.metadataParameter.id == k)?.id,
                  metadataParameterId: param.id,
                  itemId: this.item.id,
                  groupUserValue: param.modifiedByParam
                    ? users?.find((u) => u.user?.mail == this.msal.email) ??
                      (v as any)
                    : (v as any),
                }),
              ];
            else
              return [
                ...acc,
                new Metadata({
                  id: existing.find((e) => e.metadataParameter.id == k)?.id,
                  metadataParameterId: param?.id,
                  itemId: this.item.id,
                  value: v as any,
                }),
              ];
          }, [] as Metadata[]);

        const ishtarAppParams = params.filter(isIshtarAppParam);
        this.item.dataRecordGlances = Object.entries(this.itemValue)
          .filter(([k,]) => ishtarAppParams.some((p) => p.id == k))
          .map(([, v]) => v as string[])
          .reduce((acc, val) => [...acc, ...val], [])
          .map((id) => new DataRecordGlance({ recordId: id }));        

        this.libraryFacade
          .updateLibraryItems$([
            new LibraryItem({
              ...this.item,
              library: undefined,
              metadata: newMetadata,
            }),
          ])
          .subscribe(([item]) => {
            if (item.id == this.item.id) {
              this.item = item;
              this.initialValue = this.itemValue;
            }
            this.dirty = false;
            this.updating = false;
            this.updated.next(item);
          });
      });
  }

  deleteItemsInternal(items: LibraryItem[]) {
    return forkJoin(
      items.map((item) =>
        this.libraryService.deleteLibraryFile(item.sharepointId).pipe(
          map((b) => [item, b] as [LibraryItem, boolean]),
          catchError((e: HttpErrorResponse) => {
            if (e.status == 400 && e.error.includes('is locked')) {
              this.notificationService.addNotification(
                new Notification({
                  type: NotificationType.Error,
                  label: `${this.getTranslation(
                    'failedToDeleteDocument'
                  )} '${item.fileLocation
                    .split('/')
                    .pop()}' ${this.getTranslation('fileLockedByAnotherUser')}`,
                })
              );
              return of([item, false] as [LibraryItem, boolean]);
            } else return of([item, true] as [LibraryItem, boolean]);
          })
        )
      )
    ).pipe(
      switchMap((items) => {
        if (items.some((i) => !i[1]))
          this.snackbar.open(this.getTranslation('deleteFilesError'), 'OK', {
            panelClass: 'app-notification-error',
            duration: 3000,
          });
        return this.libraryFacade.deleteLibraryItems$(
          items.filter(([_, b]) => b).map(([item]) => item.id)
        );
      }),
      delay(200)
    );
  }

  deleteItem() {
    this.matDialog.open(DeleteItemsDialogComponent, {
      data: {
        items: [this.item],
        confirmAction: (itemsToDelete: LibraryItem[]) => {
          this.deleteItemsInternal(itemsToDelete).subscribe(() => {
            this.deleted.next({ reloadPage: true });
          });
        },
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
